import ivclass from '@invideoio/web-shared/Modules/IVClass';
import Image from 'next/image';

export const GenerativeSecondsCounter = ({
  value,
  incrementDisabled,
  decrementDisabled,
  onDecrementClick,
  onIncrementClick,
}: {
  value: number;
  incrementDisabled: boolean;
  decrementDisabled: boolean;
  onIncrementClick: () => void;
  onDecrementClick: () => void;
}) => {
  const assetUrl = process.env.NEXT_PUBLIC_SHARED_CDN_URL || '';

  return (
    <div
      className={ivclass(
        'iv-flex',
        'iv-pt-[12px] iv-pr-[16px] iv-pb-[12px] iv-pl-[12px]',
        'iv-items-center',
        'iv-justify-between',
        'iv-rounded-[12px]',
        'iv-bg-black-4',
      )}
    >
      <div
        className={ivclass(
          'iv-flex',
          'iv-flex-col',
          'iv-items-start',
          'iv-gap-2',
        )}
      >
        <div className={'iv-flex'}>
          <Image
            src={`${assetUrl}/pricing/bolt-outline.svg`}
            alt="bolt_outline"
            width={20}
            height={20}
          />
          <p className={'iv-text-body-2xl iv-font-medium'}>{value}</p>
        </div>
        <p className={'iv-text-body-l iv-leading-[18px] iv-font-medium'}>
          Generative credits
        </p>
      </div>
      <div className="iv-flex iv-items-center iv-rounded-[8px] iv-border iv-border-blackAlpha20 iv-bg-[white]">
        <button
          disabled={decrementDisabled}
          onClick={onDecrementClick}
          className="iv-flex iv-py-8 iv-px-12 iv-justify-center iv-items-center iv-border-r iv-border-r-blackAlpha08 disabled:iv-opacity-50 disabled:iv-cursor-not-allowed"
        >
          <Image
            src={`${assetUrl}/pricing/minus.svg`}
            alt="pricing_minus"
            width={20}
            height={20}
          />
        </button>
        <button
          disabled={incrementDisabled}
          onClick={onIncrementClick}
          className="iv-flex iv-py-8 iv-px-12 iv-justify-center iv-items-center disabled:iv-opacity-50 disabled:iv-cursor-not-allowed"
        >
          <Image
            src={`${assetUrl}/pricing/plus.svg`}
            alt="pricing_plus"
            width={20}
            height={20}
          />
        </button>
      </div>
    </div>
  );
};
