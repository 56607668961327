import ImageComponent from '@components/common/ImageComponent/ImageComponent';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import { PlatformType } from '@invideoio/web-shared/constants/common';
import { StateContext } from '@store/store';
import { replaceTripleBraces } from '@utils/pricing.utils';
import dynamic from 'next/dynamic';
import { useContext } from 'react';

const Tooltip = dynamic(
  () =>
    import(
      /* webpackChunkName: "Tooltip" */
      '@invideoio/web-shared/components/Tooltip/Tooltip'
    ),
);

interface FeatureProps {
  icon: string;
  text: string;
  quota: number;
  addOnQuota?: number;
  planKey: string;
  help?: string;
  style?: string;
}

const Feature = (props: FeatureProps) => {
  const { text, icon, quota, planKey, help, style = '' } = props;

  const globalState = useContext(StateContext);

  const { seats, platform } = globalState;

  const assetsUrl = process.env.NEXT_PUBLIC_SHARED_CDN_URL;

  return (
    <div
      className={ivclass(
        'iv-flex iv-items-center',
        'iv-relative',
        'iv-pb-[9px]',
      )}
    >
      <span className={ivclass('iv-pr-9 lg:iv-pr-[11px]')}>
        <ImageComponent
          elementype="native"
          src={icon}
          loading="lazy"
          className={ivclass('iv-inline-block')}
        />
      </span>
      <div
        className={ivclass(
          'iv-grow',
          'iv-text-black-85',
          'iv-font-medium',
          planKey === 'free' && 'iv-opacity-50',
          style,
        )}
      >
        {replaceTripleBraces(text, {
          quota: seats[planKey] * quota + (props.addOnQuota ?? 0),
        })}
      </div>
      {/* {help && platform === PlatformType.Desktop && (
        <div
          className={ivclass('iv-absolute iv-right-0 iv-top-0', 'sm:iv-hidden')}
        >
          <Tooltip content={help}>
            <ImageComponent
              elementype="native"
              src={`${assetsUrl}/pricing/info.svg`}
              loading="lazy"
              className={ivclass('iv-inline-block')}
            />
          </Tooltip>
        </div>
      )} */}
    </div>
  );
};

export default Feature;
