import ivclass from '@invideoio/web-shared/Modules/IVClass';
import Feature from './Feature';
import { AiFeature } from 'src/types/pricing.types';
import { StateContext } from '@store/store';
import { useContext } from 'react';
import { getAiCreditsFromCounter } from '../AiCredits';

interface FeaturesProps {
  featuresList: AiFeature[];
  planKey: string;
  renderPropsBottom?: () => JSX.Element;
}

const FeaturesList = (props: FeaturesProps) => {
  const { featuresList, renderPropsBottom, planKey } = props;
  const globalState = useContext(StateContext);

  const { seats, aiCreditsCounter } = globalState;

  return (
    <div
      className={ivclass('iv-py-10', 'md:iv-ml-15 sm:iv-ml-8', 'iv-flex-grow')}
    >
      {featuresList.map(({ text, icon, quota, help, style, name }, index) => {
        switch (name) {
          case 'ai_credits': {
            return (
              <Feature
                key={index}
                text={text}
                icon={icon}
                help={help}
                quota={quota}
                planKey={planKey}
                addOnQuota={getAiCreditsFromCounter(aiCreditsCounter[planKey])}
                style={`iv-text-body-xl ${style}`}
              />
            );
          }
          default:
            return (
              <Feature
                key={index}
                text={
                  name === 'user_limit' && seats[planKey] === 1
                    ? text.slice(0, -1)
                    : text
                }
                icon={icon}
                help={help}
                quota={quota}
                planKey={planKey}
                style={`iv-text-body-xl ${style}`}
              />
            );
        }
      })}

      {typeof renderPropsBottom === 'function' && renderPropsBottom()}
    </div>
  );
};

export default FeaturesList;
