import ivclass from '@invideoio/web-shared/Modules/IVClass';
import * as Select from '@radix-ui/react-select';
import Image from 'next/image';
const assetUrl = process.env.NEXT_PUBLIC_SHARED_CDN_URL || '';
import React from 'react';

export default function UserSeatsSelect({
  seats,
  onChange,
}: {
  seats: number;
  onChange: (seats: number) => void;
}) {
  const handleValueChange = (seats: string) => {
    const seat = parseInt(seats);

    if (typeof seat === 'number') {
      onChange(seat);
    }
  };
  return (
    <Select.Root onValueChange={handleValueChange} value={seats.toString()}>
      <Select.Trigger className="iv-flex iv-justify-between iv-w-[100%] iv-py-12 iv-px-16 iv-items-center iv-rounded-[12px] iv-bg-black-4">
        <div className="iv-flex iv-items-center iv-gap-8 iv-shrink-0">
          <Image
            src={`${assetUrl}/pricing/crown.svg`}
            alt="crown"
            width={20}
            height={20}
          />
          <p className="iv-text-body-2xl">
            <Select.Value /> boost
          </p>
        </div>
        <Image
          src={`${assetUrl}/pricing/arrow-down.svg`}
          alt="crown"
          width={20}
          height={20}
        />
      </Select.Trigger>
      <Select.Content
        position="popper"
        className="iv-bg-grey-10 iv-rounded-[4px] iv-z-30 iv-translate-y-1"
        style={{
          background: '#F5F5F5',
          boxShadow: '2px 2px 12px 0px rgba(0, 0, 0, 0.18)',
        }}
      >
        <Select.Viewport
          style={{
            width: '100%',
            minWidth: 'var(--radix-select-trigger-width)',
          }}
        >
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(value => (
            <Select.Item
              key={value}
              value={value.toString()}
              className={ivclass(
                'iv-p-10 iv-text-body-xl iv-rounded-[4px] iv-cursor-pointer iv-outline-none',
                'hover:iv-bg-[#E1E1E1] focus:iv-bg-[#E1E1E1]',
                'data-[state=checked]:iv-bg-[#E1E1E1]',
              )}
            >
              <Select.ItemText>{value}x</Select.ItemText>
            </Select.Item>
          ))}
        </Select.Viewport>
      </Select.Content>
    </Select.Root>
  );
}
