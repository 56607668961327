import IVButton from '@invideoio/uniformd/components/Button/index';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import { PlatformType } from '@invideoio/web-shared/constants/common';
import { DispatchContext, StateContext } from '@store/store';
import { useContext, useMemo } from 'react';
import {
  AiFeature,
  PricingDuration,
  PricingPlanTopProps,
} from 'src/types/pricing.types';
import Badge from './Badge';
import DisplayPrice from './DisplayPrice';
import { GenerativeSecondsCounter } from './GenerativeSecondsCounter';
import UserSeatsSelect from './UserSeatsSelect';
import { useAiCredits } from '../AiCredits';

const PricingPlanTop = (
  props: PricingPlanTopProps & {
    billingText: JSX.Element;
    features: Array<AiFeature>;
  },
) => {
  const {
    planName,
    planKey,
    bannerText,
    planPrice,
    CTA,
    isYearlyDuration,
    theme = 'light',
    onClick,
    disabled = false,
    classNames = '',
  } = props;
  const dispatch = useContext(DispatchContext);
  const globalState = useContext(StateContext);

  const { aiCredits, aiCreditsPrice } = useAiCredits({
    planKey,
    isYearlyDuration,
  });

  const { seats, aiCreditsCounter } = globalState;

  const billingCycle = useMemo(
    () => (isYearlyDuration ? PricingDuration.YEARLY : PricingDuration.MONTHLY),
    [isYearlyDuration],
  );

  const setSeats = (value: number) => {
    dispatch({ type: 'SET_SEATS', payload: { [planKey]: value } });
  };

  const setAiCreditsCounter = (value: number) => {
    dispatch({ type: 'SET_AI_CREDITS_COUNTER', payload: { [planKey]: value } });
  };

  const baseGenerativeCredits =
    seats[planKey] *
    (props.features.find(feature => feature.name === 'ai_credits')?.quota || 0);
  const totalGenerativeCredits = baseGenerativeCredits + aiCredits;

  return (
    <div
      className={ivclass(
        'iv-relative iv-h-[100%]',
        'iv-overflow-hidden',
        { 'iv-bg-grey-90': theme === 'dark' },
        { 'iv-bg-grey-0': theme === 'light' },
        classNames,
      )}
    >
      {bannerText && <Badge text={bannerText} />}
      <div className="iv-flex iv-flex-col iv-h-full">
        <div className="iv-flex-1">
          <div
            className={ivclass(
              'iv-pb-[12px] sm:iv-pb-[0px]',
              'iv-flex iv-justify-between',
            )}
          >
            <span
              className={ivclass(
                { 'iv-text-grey-0': theme === 'dark' },
                { 'iv-text-black-85': theme === 'light' },
                'iv-text-heading-s sm:iv-text-[30px] iv-leading-[45px]',
                'iv-font-bold',
              )}
            >
              {planName}
            </span>
            {/* <div className={ivclass('sm:iv-block iv-hidden')}>
              <DisplayPrice
                theme={theme}
                planPrice={planPrice[billingCycle]}
                seats={seats[planKey]}
                aiCreditsPrice={aiCreditsPrice}
              />
            </div> */}
          </div>

          <DisplayPrice
            theme={theme}
            planPrice={planPrice[billingCycle]}
            seats={seats[planKey]}
            aiCreditsPrice={aiCreditsPrice}
          />

          {planKey === 'free' ? (
            <div className={ivclass('iv-py-[47px] sm:iv-py-[0px]')}></div>
          ) : (
            <div className={ivclass('iv-py-20 ')}>
              <GenerativeSecondsCounter
                onDecrementClick={() =>
                  setAiCreditsCounter(aiCreditsCounter[planKey] - 1)
                }
                onIncrementClick={() => {
                  setAiCreditsCounter(aiCreditsCounter[planKey] + 1);
                }}
                decrementDisabled={aiCreditsCounter[planKey] === 0}
                incrementDisabled={aiCreditsCounter[planKey] === 9}
                value={totalGenerativeCredits}
              />
              <div className="iv-h-[16px]"></div>
              <UserSeatsSelect
                seats={seats[planKey] || 0}
                onChange={setSeats}
              />
            </div>
          )}
        </div>
        <IVButton
          className={ivclass(
            'iv-inline-flex iv-justify-center iv-items-center',
            'iv-cursor-pointer',
            'iv-rounded-full',
            'iv-px-15',
            'iv-h-52',
            'iv-w-full',
            'xl:iv-text-body-3xl lg:iv-text-body-3xl',
            'sm:iv-hidden',
            'iv-font-semibold',
            planKey === 'free'
              ? 'iv-bg-[#000]'
              : 'iv-bg-v30-primary-button-blue',
            {
              'iv-border iv-border-solid':
                CTA.backgroundColorClass === 'iv-bg-white-10' &&
                theme === 'light',
            },
            'iv-text-grey-0',
            disabled && 'iv-cursor-not-allowed',
          )}
          onClick={onClick}
          disabled={disabled}
        >
          {CTA.text}
        </IVButton>

        <div
          className={ivclass(
            'iv-text-body-m',
            'iv-text-center',
            'iv-my-5',
            'iv-h-[20px] sm:iv-hidden',
          )}
        >
          {props.billingText}
        </div>
      </div>
    </div>
  );
};

export default PricingPlanTop;
